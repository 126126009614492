import React from 'react'

import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'

import '../../styles/about.sass'

const JoinPage = () => {
    return (
        <Layout languageSwitchLink="/join">
            <SEO title="Join" />
            <Banner class_suffix="deep" />
            <div className="about-container">
                <div className="about-content">
                 {/*    <p>
                        Join our community to develop your cybersecurity skills!
                    </p> */}

                    {/* Section for technical workshops */}
                    <h3>Join the Hacker Nights and technical workshops</h3>
                    <p>
                        Hacker Nights are weekly events where we solve cybersecurity challenges together (or not). Every Tuesday from 6 PM to 9 PM in room L-2708!
                    </p>
                    <p>
                        The workshops aim to delve deeper into various cybersecurity topics and improve participants' skills. Depending on the workshop, the level ranges from beginner to advanced.
                    </p>
                    <div className="button-group">
                        <a href="https://discord.gg/ZcYnS3GZE6">
                            <button className="signup-button">Join our Discord</button>
                        </a>
                    </div>

                    {/* Section for getting involved */}
                    <h3>Get more involved!</h3>
                    <p>
                        By joining the executive team or the CTF team, you can become more actively involved in our club’s activities, whether organizing events or participating in cybersecurity competitions.
                    </p>

                    {/* Container for the two involvement options */}
                    <div className="team-options">
                        {/* Option to join the executive team */}
                        <div className="team-option">
                            <h2>Executive Team</h2>
                            <p>
                                The executive team plans technical workshops, organizes CTF (Capture-the-Flag) competitions, develops industry partnerships, communicates new events, and much more!
                            </p>
                            <a href="https://forms.gle/Q5PedtHzsSjAjCLn6">
                                <button className="signup-button">Join our Executive Team!</button>
                            </a>
                        </div>

                        {/* Option to join the CTF team */}
                        <div className="team-option">
                            <h2>CTF Team</h2>
                            <p>
                                The CTF team participates in competitions throughout the year, including prestigious events like <a href="https://nsec.io/">NorthSec</a> and <a href="https://hfctf.ca/">HackFest</a>. Join us to push your limits in technical cybersecurity challenges! (You must be a student at Polytechnique Montréal.)
                            </p>
                            <a href="https://forms.gle/nRu5TeagsmhXCqFw8">
                                <button className="signup-button">Join our CTF Team!</button>
                            </a>
                        </div>
                    </div>

                    <p>
                         Check out our ranking on <a href="https://ctftime.org/team/59057">CTFTime</a>.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default JoinPage
